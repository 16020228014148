import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from "axios";

import { Plant } from "../../types/plant";
import { FilterValues } from "../../types/filtervalues"

import { getPlants } from "../../hooks/PlantHooks";

import Header from "../../main/frame/Header";
import PlantFilterForm from "./PlantFilterForm";
import PlantListRow from "./PlantListRow";
import Quote from '../shared/Quote';


const defaultFilter = {
    searchColumn: ''
  , searchTerm: ''
  , sortColumn: ''
  , sortDirection: ''
}

const localKeyFilter: string = "cpPlantFilter";

const getFilter = () => {

  let localFilter = window.sessionStorage.getItem(localKeyFilter);

  if (localFilter) {
    return JSON.parse(localFilter); 
  }

  return defaultFilter;
}


const PlantList = () => {

    let [filter, setFilter] = useState<FilterValues>(getFilter());

    const { data } = useQuery<Plant[], AxiosError>({
                                                    queryKey: ["plantlist", filter.searchColumn, filter.searchTerm, filter.sortColumn, filter.sortDirection]
                                                  , queryFn: () => getPlants(filter)
                                                  , staleTime: 3600000
                                                });

    /* get/set filter in local storage */
    useEffect(() => {
      let localFilter = window.sessionStorage.getItem(localKeyFilter);
      if (localFilter) {
        setFilter(JSON.parse(localFilter));
      }
    }, []);
  
    useEffect(() => {
      const strFilter = JSON.stringify(filter);
      window.sessionStorage.setItem(localKeyFilter, strFilter);
    }, [filter]);


    /* filter onchange methods */
    const onChangeFilterText = (inputName: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
          setFilter({ ...filter, [inputName]: event.target.value });
        };
    };

    const onChangeFilterDDL = (inputName: string) => {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
          setFilter({ ...filter, [inputName]: event.target.value });
        };
    };

    const onChangeFilterRadio = (inputName: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {

          let direction = 'asc';

          // const btnsRadio = document.getElementsByName("SortDirection") as NodeListOf<HTMLInputElement>; // in this case, no need to get all radio buttons
          const radioDesc = document.getElementById("sort_dir_desc") as HTMLInputElement;
          if (radioDesc.checked === true)
            direction = 'desc';

            setFilter({ ...filter, [inputName]: direction });
        };
    };


    return (
      <div className="container main_content_box">
        <Header subtitle="Flora"/>
        <div className="row">
            <div className="col-md-12 intro_copy">
                <p>Searchable, sortable list of Plants.</p>
                <p>These are the plants in the yard.  Previous homeowners planted a few of them.  My sister planted a few.  A few others planted themselves.  I planted the rest.</p>
                <p>* Plant information taken from the sources listed on the 'Resources' page, along with my own experience.</p>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 search_tool_col">
              <PlantFilterForm searchColumn={filter.searchColumn} searchTerm={filter.searchTerm} sortColumn={filter.sortColumn} sortDirection={filter.sortDirection} onChangeFilterText={onChangeFilterText} onChangeFilterDDL={onChangeFilterDDL} onChangeFilterRadio={onChangeFilterRadio} />
            </div>
        </div>
        <div className="row">
          <div id="plant_list_box" className="col-md-12">
            <div className="table-responsive">
                  <table id="tbl_animal_list" className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th className="cell_center">Thumb</th>
                            <th>Common Name</th>
                            <th>Scientific Name</th>
                            <th className="cell_center">Type</th>
                            <th className="cell_center">Sun</th>
                            <th className="cell_center">Moisture</th>
                            <th className="cell_center">Height</th>
                            <th className="cell_center">Width</th>
                            <th className="cell_center">Season</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data && data.map(x => <PlantListRow {...x} key={x.plantId}/>)}{/* {...x} = spread syntax to apply all properties */}
                    </tbody>
                </table>
            </div>
          </div>
        </div>
        <Quote/>
      </div>
    );
  };
  
  export default PlantList;
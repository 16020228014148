import Quote from "../shared/Quote";
import HomeBoxLink from "./HomeBoxLink"
import HomeCarousel from './HomeCarousel';


const Home = () => {

    return (

        <main className="container">

            <div className="home_banner">
                <HomeCarousel/>
            </div>

            <div className="home_intro_box">
                <div className="home_intro">
                    <p className="home_intro_title">Welcome to Chickadee Park!</p>
                    <p>
                        In 2003, I came across some information on designing landscapes with native plants to benefit local wildlife. It seemed like a fun idea and I decided to give it a try.
                    </p>
                    <p>
                        Since then, I've been gradually filling up the yard with native plants.<br/>
                        It continues to surprise me as I find out what a backyard is capable of, even in an urban area.
                    </p>
                    <p>
                        This site shows how things are going...
                    </p>
                </div>
            </div>

            <div className="home_divider_box">
                <div className="container">
                    <div className="home_divider">
                        &nbsp;
                    </div>
                </div>
            </div>

            <div className="box_links_box">
                <div className="container">
                    <div className="box_links">
                        <div className="row">
                            <HomeBoxLink href="plans/blueprint2" imgFileName="home_th_blueprint.jpg" altText="Critters" hoverText="View the blueprints" linkId={0} text="" target="" />
                            <HomeBoxLink href="resources" imgFileName="home_th_resources.jpg" altText="Resources" hoverText="View the resources" linkId={0} text="" target="" />
                            <HomeBoxLink href="flora" imgFileName="home_th_plants.jpg" altText="Critters" hoverText="Go to the plant list" linkId={0} text="" target="" />
                        </div>
                        <div className="row">
                            <HomeBoxLink href="fauna" imgFileName="home_th_critters.jpg" altText="Critters" hoverText="Go to the critter list" linkId={0} text="" target="" />
                            <HomeBoxLink href="photos" imgFileName="home_th_photos.png" altText="Photos" hoverText="See the photos" linkId={0} text="" target="" />
                            <HomeBoxLink href="contact" imgFileName="home_th_contact.png" altText="Contact me" hoverText="Send a comment, correction, or whatever" linkId={0} text="" target="" />
                        </div>
                    </div>
                </div>
            </div>
            <Quote/>
        </main>
    );
  };
  
  export default Home;







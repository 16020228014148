import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import { PhotoArray } from '../../types/photo'

import PhotoModule from './PhotoModule';


function Gallery({photos}: PhotoArray) {

    const onInit = () => {
        console.log('lightGallery has been initialized');
    };


    return (
        <div className="App">
            <LightGallery
                onInit={onInit}
                speed={500}
                licenseKey='6C7E37A9-A5994724-A3FE389A-1C4C08C9' 
                subHtmlSelectorRelative={true}
                // plugins={[lgThumbnail, lgZoom]}
                >

                {photos.map(x => <PhotoModule {...x} key={x.photoId} />)} 

            </LightGallery>
        </div>
    );
}

export default Gallery;
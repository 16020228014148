import { Photo } from "../../types/photo"


const PhotoModule = ({...p}: Photo) =>  {

    const title = `Photo ID: ${p.photoId}`;
    const cssClass = (p.height > p.width) ? "ph_thumb_portrait" : "ph_thumb_landscape";

    return (
        <a href={p.fileName} title={title} data-description={p.caption} className="photo_thumb " data-download-url='false' data-sub-html=".cpCaption">
            <img src={p.thumbFileName} alt={p.alt} className={cssClass}/>
            <div className="cpCaption">
                <p>PhotoID: {p.photoId}</p>
                <p>{p.caption}</p>
            </div>
        </a>
    );
}

export default PhotoModule;
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from "axios";
// import axios, { AxiosError, AxiosResponse } from "axios";

import ReactPaginate from 'react-paginate';
import ResponsivePagination from 'react-responsive-pagination';

import { Photo } from "../../types/photo";
import { PhotoPage } from '../../types/photopage';
import { FilterValues } from "../../types/filtervalues"

import { getPhotos } from "../../hooks/PhotoHooks";

import Header from "../../main/frame/Header";
import Quote from "../shared/Quote";
import PhotoFilterForm from "./PhotoFilterForm";
import Gallery from '../shared/Gallery';
import NoResults from '../shared/NoResults';


const defaultFilter = {
    category: ''
  , searchColumn: ''
  , searchTerm: ''
  , sortColumn: 'dtu'
  , sortDirection: 'desc'
  , pageId: 1
}

const localKeyFilter: string = "cpPhotoFilter";

const getFilter = () => {

    let localFilter = window.sessionStorage.getItem(localKeyFilter);
  
    if (localFilter) {
      return JSON.parse(localFilter); 
    }
  
    return defaultFilter;
  }


const Photos = () => {
  
    let [filter, setFilter] = useState<FilterValues>(getFilter());

    // const { data, isLoading, isError } = useQuery<PhotoPage, AxiosError>({
    const { data } = useQuery<PhotoPage, AxiosError>({
                                                      queryKey: ["photolist", filter.searchColumn, filter.searchTerm, filter.sortColumn, filter.sortDirection, filter.category, filter.pageId]
                                                    , queryFn: () => getPhotos(filter)
                                                    , staleTime: 3600000
                                                    });

    let pageCount = (data && data.totalPageCount) ? data.totalPageCount : 0;

    /* get/set filter in local storage */
    useEffect(() => {
        let localFilter = window.sessionStorage.getItem(localKeyFilter);
        if (localFilter) {
          setFilter(JSON.parse(localFilter));
        }
      }, []);
    
    useEffect(() => {
        const strFilter = JSON.stringify(filter);
        window.sessionStorage.setItem(localKeyFilter, strFilter);
      }, [filter]);

    /* filter onchange methods */
    const onChangeFilterText = (inputName: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
          setFilter({ ...filter, pageId: 1, [inputName]: event.target.value });
        };
    };

    const onChangeFilterDDL = (inputName: string) => {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
          setFilter({ ...filter, pageId: 1, [inputName]: event.target.value });
        };
    };

    const onChangeFilterRadio = (inputName: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {

          let direction = 'asc';

          // const btnsRadio = document.getElementsByName("SortDirection") as NodeListOf<HTMLInputElement>; // in this case, no need to get all radio buttons
          const radioDesc = document.getElementById("sort_dir_desc") as HTMLInputElement;
          if (radioDesc.checked === true)
            direction = 'desc';

            setFilter({ ...filter, pageId: 1, [inputName]: direction });
        };
    };

    let pics: Photo[] = [];  // declare array in typescript

    if (data) {
        data.items.forEach((p) => {

            const imgPathFull = `/assets/images/_full/${p.fileName}`; // note: use 'backtick' character (not single or double quote) see backtick/tilde key
            const imgThumbPath = `/assets/images/_thumb/${p.thumbFileName}`;

            pics.push({photoId: p.photoId, fileName: imgPathFull, thumbFileName: imgThumbPath, alt: p.alt, height: p.height, width: p.width, caption: p.caption, isKey: false})
        })
    }

    const handlePageClick = (page: number): void => {
      setFilter(filter => ({...filter, pageId: page}));
  }


    return (

        <main className="container">
            <Header subtitle="Photos"/>
            <div className="row">
                <div className="col-md-12 intro_copy">
                    <p>I'm not a great photographer.  But it's fun.</p>
                    <p>Click a thumbnail to view the larger image and the slideshow.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 search_tool_col">
                <PhotoFilterForm searchColumn={filter.searchColumn} searchTerm={filter.searchTerm} sortColumn={filter.sortColumn} sortDirection={filter.sortDirection} onChangeFilterText={onChangeFilterText} onChangeFilterDDL={onChangeFilterDDL} onChangeFilterRadio={onChangeFilterRadio} />
                </div>
            </div>
            <div className="row photo-pgbtns-box">
                <div className="col-sm-12">
                      <ResponsivePagination
                        current={filter.pageId}
                        total={pageCount}
                        onPageChange={handlePageClick}
                      />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div id="photo_gallery_box"  className="row">
                        <Gallery photos={pics}/>
                    </div>
                </div>
            </div>
            <Quote/>
        </main>
    );
  };
  
  export default Photos;